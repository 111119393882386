<template>
  <div class="row invoice-add">
    <div class="col-12 col-md-6">
      <b-card no-body class="invoice-preview-card">
        <b-card-body class="invoice-padding">
          <router-link class="btn btn-outline-secondary mb-3" :to="{ name: `${this.prefix}-categories` }">
            <span class="text-nowrap">Назад</span>
          </router-link>
          <h4 class="invoice-title mb-1">Создание новой категории</h4>
          <div class="row">
            <div class="col-12">
              <label class="title">Родительская категория *</label>
              <treeselect
                  v-model="category.parent_id"
                  :options="categories"
                  :searchable="true"
                  :normalizer="normalizer"
                  placeholder="Выберите родительскую категорию"
              />
            </div>
            <div class="col-12">
              <label class="title">Название *</label>
              <input type="text" class="form-control" v-model="category.name">
            </div>
            <div class="col-12">
              <label class="title">External ID</label>
              <input type="text" class="form-control" v-model="category.external_id">
            </div>
            <div class="col-12 mt-2">
              <b-button @click="saveCategory" variant="primary">
                <span class="text-nowrap">Сохранить</span>
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BCardBody,
  VBToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'ExternalCategoryCreate',
  props: ['externalServiceId', 'prefix'],
  components: {
    BCard,
    BCardBody,
    BButton,
    Treeselect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      category: {
        parent_id: null,
        name: null,
        external_id: null,
      },
      searchQuery: null,
      categories: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
    };
  },
  async mounted() {
    await this.getExternalCategories();
  },
  methods: {
    async getExternalCategories() {
      this.categories = (await this.$api.external.categories.select(this.externalServiceId)).data;
    },
    async saveCategory() {
      if (!this.category.name) {
        this.$bvToast.toast('Заполните все поля', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return false;
      }

      try {
        await this.$api.external.categories.create(this.externalServiceId, this.category);
        this.$bvToast.toast('Категория успешно создана', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$router.push({ name: `${this.prefix}-categories` });
      } catch (e) {
        this.$bvToast.toast('Ошибка при создании категории', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.invoice-edit-input {
  max-width: 100% !important;
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.custom-radio {
  padding-right: 10px;
}
</style>
